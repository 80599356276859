/* Layout */
import Layout from '@/views/ruge/layout/Layout';

/**
 * 平台框架相关路由
 */
const rugeRouter = [
  {
    path: '/common/',
    component: Layout,
    children: [
      {
        path: 'frame',
        name: 'commonFrame',
        component: () => import('@/views/ruge/common/frame'),
      },
      {
        path: 'customDashboard',
        name: 'customDashboard',
        component: () => import('@/views/ruge/common/customDashboard'),
      },
    ],
  },
  {
    path: '/sys/permission/',
    component: Layout,
    redirect: '/sys/permission/',
    children: [
      {
        path: 'grant',
        name: 'GrantManager',
        component: () => import('@/views/ruge/grant/index'),
      },
      {
        path: 'grant/allotRole',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          keepVal: true,
          name: '分配角色',
          goBackRouter: '/sys/permission/grant',
        },
        name: 'SysGrantAllotRole',
        component: () => import('@/views/ruge/grant/allotRole.vue'),
      },
      {
        path: 'grant/allotData',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          keepVal: true,
          name: '数据范围',
          goBackRouter: '/sys/permission/grant',
        },
        name: 'SysGrantAllotData',
        component: () => import('@/views/ruge/grant/allotData.vue'),
      },
      {
        path: 'role',
        name: 'RoleManager',
        component: () => import('@/views/ruge/role/index'),
      },
      {
        path: 'role/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          keepVal: true,
          name: '分配权限',
          goBackRouter: '/sys/permission/role',
        },
        name: 'SysRoleDetail',
        component: () => import('@/views/ruge/role/detail.vue'),
      },
      {
        path: 'program',
        name: 'ProgramManager',
        component: () => import('@/views/ruge/program/index'),
      },
      {
        path: 'program/allotPermission',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          keepVal: true,
          name: '数据维度',
          goBackRouter: '/sys/permission/program',
        },
        name: 'SysProgramAllotPermission',
        component: () => import('@/views/ruge/program/allotPermission.vue'),
      },
      {
        path: 'program/viewDimensionTab',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          keepVal: true,
          name: '维度列表',
          goBackRouter: '/sys/permission/program',
        },
        name: 'SysProgramViewDimensionTab',
        component: () => import('@/views/ruge/program/dimensionList.vue'),
      },
    ],
  },
  {
    path: '/tenant/permission/',
    component: Layout,
    redirect: '/tenant/permission/',
    children: [
      {
        path: 'grant',
        name: 'TenantGrantManager',
        component: () => import('@/views/ruge/grant/tenant/index'),
      },
      {
        path: 'grant/allotRole',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          keepVal: true,
          name: '分配角色',
          goBackRouter: '/tenant/permission/grant',
        },
        name: 'TenantGrantAllotRole',
        component: () => import('@/views/ruge/grant/tenant/allotRole.vue'),
      },
      {
        path: 'grant/allotData',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          keepVal: true,
          name: '数据范围',
          goBackRouter: '/tenant/permission/grant',
        },
        name: 'TenantGrantAllotData',
        component: () => import('@/views/ruge/grant/tenant/allotData.vue'),
      },
      {
        path: 'role',
        name: 'TenantRoleManager',
        component: () => import('@/views/ruge/role/tenant/index'),
      },
      {
        path: 'program',
        name: 'TenantProgramManager',
        component: () => import('@/views/ruge/program/tenant/index'),
      },
      {
        path: 'program/allotPermission',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          keepVal: true,
          name: '数据维度',
          goBackRouter: '/tenant/permission/program',
        },
        name: 'TenantProgramAllotPermission',
        component: () =>
          import('@/views/ruge/program/tenant/allotPermission.vue'),
      },
      {
        path: 'role/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          keepVal: true,
          name: '分配权限',
          goBackRouter: '/tenant/permission/role',
        },
        name: 'TenantRoleDetail',
        component: () => import('@/views/ruge/role/tenant/detail'),
      },
    ],
  },
  {
    path: '/sitemap',
    component: Layout,
    redirect: '/sitemap/list',
    children: [
      {
        path: 'list',
        name: 'Sitemap',
        component: () => import('@/views/ruge/sitemap/indexNew'),
      },
      {
        path: 'tenantList',
        name: 'tenantSitemap',
        component: () => import('@/views/ruge/sitemap/tenantSiteMap'),
      },
    ],
  },
  {
    path: '/notify',
    component: Layout,
    redirect: '/notify/list',
    children: [
      {
        path: 'list',
        name: 'Notify',
        component: () => import('@/views/ruge/notify/index'),
      },
    ],
  },
  {
    path: '/tenant/notify/',
    component: Layout,
    redirect: '/tenant/notify/list',
    children: [
      {
        path: 'list',
        name: 'TenantNotify',
        component: () => import('@/views/ruge/notify/tenant/index'),
      },
    ],
  },
  {
    path: '/tag',
    component: Layout,
    redirect: '/tag/manager',
    children: [
      {
        path: 'manager',
        name: 'Tag',
        component: () => import('@/views/ruge/tag/index'),
      },
    ],
  },
  {
    path: '/lookup',
    component: Layout,
    redirect: '/lookup/list',
    children: [
      {
        path: 'list',
        name: 'Lookup',
        component: () => import('@/views/ruge/lookup/index'),
      },
      {
        path: 'tenantList',
        name: 'tenantLookup',
        component: () => import('@/views/ruge/lookup/tenant/index'),
      },
    ],
  },
  {
    path: '/registry',
    component: Layout,
    redirect: '/registry/list',
    children: [
      {
        path: 'list',
        name: 'Registry',
        component: () => import('@/views/ruge/registry/index'),
      },
    ],
  },
  {
    path: '/ops',
    component: Layout,
    redirect: '/ops/auditLog',
    children: [
      {
        path: 'redisManager',
        name: 'redisManager',
        component: () => import('@/views/ruge/ops/redisManager'),
      },
      {
        path: 'asyncTaskTrace',
        name: 'AsyncTaskTrace',
        component: () => import('@/views/ruge/ops/asyncTaskTrace'),
      },
      {
        path: 'systemLog',
        name: 'SystemLog',
        component: () => import('@/views/ruge/ops/systemLog'),
      },
      {
        path: 'consulManager',
        name: 'ConsulManager',
        component: () => import('@/views/ruge/ops/consul/index'),
      },
    ],
  },
  // 流媒体管理
  {
    path: '/stream',
    component: Layout,
    redirect: '/stream/streamManager',
    children: [
      {
        path: 'streamManager',
        name: 'StreamManager',
        component: () => import('@/views/ruge/stream/streamManager'),
      },
    ],
  },
  // 操作日志
  {
    path: '/logger',
    component: Layout,
    redirect: '/logger/auditLog',
    children: [
      {
        path: 'auditLog',
        name: 'AuditLog',
        component: () => import('@/views/ruge/logger/auditLog'),
      },
      {
        path: 'logger/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=日志详情;en-US=Logger Detail',
          goBackRouter: '/logger/auditLog',
        },
        name: 'VlinkLoggerDetail',
        component: () => import('@/views/ruge/logger/logDetail'),
      },
    ],
  },
  {
    path: '/tenant/logger',
    component: Layout,
    redirect: '/tenant/logger/auditLog',
    children: [
      {
        path: 'auditLog',
        name: 'TenantAuditLog',
        component: () => import('@/views/ruge/logger/tenant/auditLog'),
      },
      {
        path: 'logger/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=日志详情;en-US=Logger Detail',
          goBackRouter: '/tenant/logger/auditLog',
        },
        name: 'VlinkTenantLoggerDetail',
        component: () => import('@/views/ruge/logger/tenant/logDetail'),
      },
    ],
  },
  {
    path: '/tenant/login/logger',
    component: Layout,
    redirect: '/tenant/login/logger/auditLog',
    children: [
      {
        path: 'auditLog',
        name: 'TenantLoginAuditLog',
        component: () => import('@/views/ruge/logger/tenant/auditLoginLog'),
      },
    ],
  },
  // 微信管理
  {
    path: '/wxManage',
    component: Layout,
    redirect: '/wxManage/list',
    children: [
      {
        path: 'list',
        name: 'wxManage',
        component: () => import('@/views/ruge/wxManage/index'),
      },
    ],
  },
  // 短信发送信息
  {
    path: '/smsManage',
    component: Layout,
    redirect: '/smsManage/list',
    children: [
      {
        path: 'list',
        name: 'smsManage',
        component: () => import('@/views/ruge/smsManage/index'),
      },
    ],
  },
  // 短信发送信息
  {
    path: '/tenant/smsManage',
    component: Layout,
    redirect: '/tenant/smsManage/list',
    children: [
      {
        path: 'list',
        name: 'smsTenantManage',
        component: () => import('@/views/ruge/smsManage/tenantIndex'),
      },
    ],
  },
  // 短信模板配置
  {
    path: '/smsTemplate',
    component: Layout,
    redirect: '/smsTemplate/list',
    children: [
      {
        path: 'list',
        name: 'smsTemplate',
        component: () => import('@/views/ruge/smsTemplate/index'),
      },
    ],
  },

  {
    path: '/tenant/smsTemplate',
    component: Layout,
    redirect: '/tenant/smsTemplate/list',
    children: [
      {
        path: 'list',
        name: 'smsTenantTemplate',
        component: () => import('@/views/ruge/smsTemplate/tenantIndex'),
      },
    ],
  },
  // 人员管理
  {
    path: '/employee',
    component: Layout,
    redirect: '/employee/list',
    children: [
      {
        path: 'list',
        name: 'employeeManager',
        component: () => import('@/views/ruge/employee/index'),
      },
    ],
  },
  {
    path: '/tenant/employee/',
    component: Layout,
    redirect: '/tenant/employee/list',
    children: [
      {
        path: 'list',
        name: 'TenantEmployeeManager',
        component: () => import('@/views/ruge/employee/tenant/index'),
      },
    ],
  },
  // 组织结构
  {
    path: '/organization',
    component: Layout,
    redirect: '/organization/manager',
    children: [
      {
        path: 'manager',
        name: 'OrganizationManager',
        component: () => import('@/views/ruge/organization/index'),
      },
    ],
  },
  // 短信上限
  {
    path: '/messageLimit',
    component: Layout,
    redirect: '/messageLimit/list',
    children: [
      {
        path: 'list',
        name: 'messageLimit',
        component: () => import('@/views/ruge/messageLimit/index'),
      },
    ],
  },
  {
    path: '/tenant/organization/',
    component: Layout,
    redirect: '/tenant/organization/manager',
    children: [
      {
        path: 'manager',
        name: 'TenantOrganizationManager',
        //      component: () => import('@/views/ruge/organization/tenant/index'),
        component: () => import('@/views/business/organization'),
      },
    ],
  },
  //租户
  {
    path: '/tenant',
    component: Layout,
    redirect: '/tenant/manager',
    children: [
      {
        path: 'manager',
        name: 'Tenant',
        component: () => import('@/views/ruge/tenant/index'),
      },
      {
        path: 'extendConfig',
        name: 'ExtendConfig',
        component: () => import('@/views/ruge/extendConfig/index'),
      },
    ],
  },
  //模块
  {
    path: '/modulx',
    component: Layout,
    redirect: '/modulx/template',
    children: [
      {
        path: 'template',
        name: 'ModulxTemplate',
        component: () => import('@/views/ruge/modulx/template'),
      },
      {
        path: 'tenant',
        name: 'ModulxTenant',
        component: () => import('@/views/ruge/modulx/tenantModulx'),
      },
      {
        path: 'pricing',
        name: 'ModulxPricing',
        component: () => import('@/views/ruge/modulx/pricingModulx'),
      },
    ],
  },
  // SSO 账号管理
  {
    path: '/sso/account/manager',
    component: Layout,
    redirect: '/sso/account/manager/list',
    children: [
      {
        path: 'list',
        name: 'SsoUserManager',
        component: () => import('@/views/ruge/sso/manager/indexComponent.vue'),
        // component: () => import('@/views/ruge/sso/manager/index'),
      },
    ],
  },
  // 定时任务管理
  {
    path: '/clocky/job',
    component: Layout,
    redirect: '/clocky/job/list',
    children: [
      {
        path: 'list',
        name: 'JobManager',
        component: () => import('@/views/ruge/clocky/job/index'),
      },
    ],
  },
  //IOT
  // {
  //     path: '/iot',
  //     component: Layout,
  //     redirect: '/iot/real/alarm',
  //     children: [
  //         {
  //             path: 'mqtt/server',
  //             name: 'MqttServerX',
  //             component: () => import('@/views/ruge/iot/mqtt/index')
  //         },
  //         {
  //             path: 'real/alarm',
  //             name: 'RealAlarm',
  //             component: () => import('@/views/ruge/iot/alarm/realAlarm')
  //         },
  //         {
  //             path: 'history/alarm',
  //             name: 'HistoryAlarm',
  //             component: () => import('@/views/ruge/iot/alarm/historyAlarm')
  //         },
  //         {
  //             path: 'history/point',
  //             name: 'PointData',
  //             component: () => import('@/views/ruge/iot/point/data')
  //         },
  //         {
  //             path: 'device/list',
  //             name: 'DeviceList',
  //             component: () => import('@/views/ruge/iot/device/index')
  //         },
  //         {
  //             path: 'device/icon',
  //             name: 'DeviceIcon',
  //             component: () => import('@/views/ruge/iot/device/deviceIcon')
  //         },
  //         {
  //             path: 'gateway/list',
  //             name: 'GatewayList',
  //             component: () => import('@/views/ruge/iot/gateway/index')
  //         },
  //         {
  //             path: 'device/monitor',
  //             name: 'DeviceMonitor',
  //             component: () => import('@/views/ruge/iot/monitor/deviceMonitor')
  //         },
  //         {
  //             path: 'camera/list',
  //             name: 'CameraList',
  //             component: () => import('@/views/ruge/iot/camera/index')
  //         },
  //         {
  //             path: 'thing/list',
  //             name: 'ThingList',
  //             component: () => import('@/views/ruge/iot/thing/index')
  //         },
  //         {
  //             path: 'scene/list',
  //             name: 'sceneRuleList',
  //             component: () => import('@/views/ruge/iot/scene/index')
  //         },
  //         {
  //             path: 'protocol/list',
  //             name: 'protocolList',
  //             component: () => import('@/views/ruge/iot/protocol/index')
  //         }
  //
  //     ]
  // },
  // 流程引擎
  {
    path: '/workflow',
    component: Layout,
    redirect: '/workflow/list',
    children: [
      {
        path: 'detail',
        name: 'WorkflowDetail',
        component: () => import('@/views/ruge/workflow/detail'),
      },
      {
        path: 'list',
        name: 'WorkflowList',
        component: () => import('@/views/ruge/workflow/list'),
      },
    ],
  },
  // 流程引擎
  {
    path: '/approval-process',
    component: Layout,
    redirect: '/approval-process/all',
    children: [
      {
        path: 'all',
        name: 'approvalProcessAll',
        component: () => import('@/views/ruge/approvalProcess/all'),
      },
      {
        path: 'myReviewed',
        name: 'approvalProcessMyReviewed',
        component: () => import('@/views/ruge/approvalProcess/myReviewed'),
      },
      {
        path: 'pendingReview',
        name: 'approvalProcessPendingReview',
        component: () => import('@/views/ruge/approvalProcess/pendingReview'),
      },
      {
        path: 'draft',
        name: 'approvalProcessDraft',
        component: () => import('@/views/ruge/approvalProcess/draft'),
      },
      {
        path: 'overview',
        name: 'approvalProcessOverview',
        component: () => import('@/views/ruge/approvalProcess/overview'),
      },
      {
        path: 'management',
        name: 'approvalProcessManagement',
        component: () => import('@/views/ruge/approvalProcess/management'),
      },
      {
        path: 'myReadTable',
        name: 'approvalProcessMyReadTable',
        component: () => import('@/views/ruge/approvalProcess/myReadTable'),
      },
    ],
  },
  {
    path: '/workflow/flowBpnm',
    name: 'flowBpnm',
    component: () => import('@/views/ruge/workflow/FlowBpmn'),
  },
  {
    path: '/workflow/publishRecord',
    name: 'PublishRecord',
    component: () => import('@/views/ruge/workflow/publishRecord'),
  },
  // vlink
  {
    path: '/iot',
    component: Layout,
    redirect: '/iot/device/list',
    children: [
      {
        path: 'relayService',
        name: 'VlinkRelayService',
        component: () => import('@/views/ruge/vlink/relayService'),
      },
      {
        path: 'dynamicForm',
        name: 'VlinkDynamicForm',
        component: () => import('@/views/ruge/vlink/dynamicForm'),
      },
      {
        path: 'topology/list',
        name: 'VlinkTopology',
        component: () => import('@/views/ruge/vlink/topology'),
      },
      {
        path: 'lightControl',
        name: 'lightControl',
        component: () => import('@/views/ruge/vlink/lightControl'),
      },
      {
        path: 'topology/detail',
        name: 'VlinkTopologyDetail',
        meta: {
          detail: true,
          name: 'zh-CN=拓扑详情;en-US=Topology Detail',
          goBackRouter: '/iot/topology/list',
        },
        component: () => import('@/views/ruge/vlink/topology/detail'),
      },
      {
        path: 'topology/preview',
        name: 'VlinkTopologyPreview',
        meta: {
          detail: true,
          name: 'zh-CN=拓扑预览;en-US=Topology Preview',
          goBackRouter: '/iot/topology/list',
        },
        component: () => import('@/views/ruge/vlink/topology/detail/preview'),
      },
      {
        path: 'dashboard',
        name: 'VlinkDashboard',
        component: () => import('@/views/ruge/vlink/dashboard'),
      },
      {
        path: 'product/list',
        name: 'VlinkProductList',
        component: () => import('@/views/ruge/vlink/product/indexComponent'),
      },
      {
        path: 'product/detail',
        name: 'VlinkProductDetail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=产品详情;en-US=Product Detail',
          goBackRouter: '/iot/product/list',
        },
        component: () => import('@/views/ruge/vlink/product/detail'),
      },
      {
        path: 'device/list',
        name: 'VlinkDeviceList',
        component: () => import('@/views/ruge/vlink/device/indexComponent'),
      },
      {
        path: 'device/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=设备详情;en-US=Device Detail',
          goBackRouter: '/iot/device/list',
        },
        name: 'VlinkDeviceDetail',
        component: () => import('@/views/ruge/vlink/device/detail'),
      },
      {
        path: 'rule/list',
        name: 'VlinkRuleList',
        component: () => import('@/views/ruge/vlink/rule/indexComponent'),
      },
      {
        path: 'rule/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=规则详情;en-US=Rule Detail',
          goBackRouter: '/iot/rule/list',
        },
        name: 'VlinkRuleDetail',
        component: () => import('@/views/ruge/vlink/rule/detail'),
      },
      {
        path: 'rule/release',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=版本记录;en-US=Rule Release',
          goBackRouter: '/iot/rule/list',
        },
        name: 'VlinkRuleRelease',
        component: () => import('@/views/ruge/vlink/rule/release'),
      },
      {
        path: 'consumerGroup/manage',
        name: 'consumerGroupManage',
        component: () =>
          import('@/views/ruge/vlink/consumerGroup/indexComponent'),
      },
      {
        path: 'videoSquare/list',
        name: 'VlinkVideoList',
        component: () => import('@/views/ruge/vlink/videoSquare/index'),
      },
      {
        path: 'JinweiVideo/list',
        name: 'JinweiVideoList',
        component: () => import('@/views/ruge/vlink/videoSquare/JinweiVideo'),
      },
      {
        path: 'subDeviceGroup/list',
        name: 'subDeviceGroup',
        component: () => import('@/views/ruge/vlink/subDeviceGroup/index'),
      },
      {
        path: 'protocol/list',
        name: 'protocolList',
        component: () =>
          import('@/views/ruge/vlink/device/protocol/protocolIndex'),
      },
      {
        path: 'appManage/list',
        name: 'appManage',
        component: () => import('@/views/ruge/vlink/appManage/index'),
      },
      {
        path: 'appManage/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=应用详情;en-US=Application Detail',
          goBackRouter: '/iot/appManage/list',
        },
        name: 'VlinkAppManageDetail',
        component: () => import('@/views/ruge/vlink/appManage/detail'),
      },
      {
        path: 'propertyQuery/list',
        name: 'propertyQuery',
        component: () => import('@/views/ruge/vlink/propertyQuery/index'),
      },
      {
        path: 'ArchivesCenterVideo/list',
        name: 'ArchivesCenterVideoList',
        component: () =>
          import('@/views/ruge/vlink/videoSquare/ArchivesCenterVideo'),
      },
      {
        path: 'alarm/list',
        name: 'VlinkAlarmList',
        component: () => import('@/views/ruge/vlink/alarm/indexNew'),
      },
      {
        path: 'alarm/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          keepVal: true,
          name: 'zh-CN=告警详情;en-US=Alarm Detail',
          goBackRouter: '/iot/alarm/list',
        },
        name: 'VlinkAlarmDetail',
        component: () => import('@/views/ruge/vlink/alarm/detail'),
      },

      {
        path: 'alarmRecords/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=告警记录详情;en-US=alarmRecords Detail',
          goBackRouter: '/tenant/base/alarmRecords/list',
        },
        name: 'alarmRecordsDetail',
        component: () => import('@/views/business/base/alarmRecords/detail'),
      },
      {
        path: 'orderQuery/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          keepVal: true,
          name: 'zh-CN=工单详情;en-US=Order Detail',
          goBackRouter: '/tenant/order/orderQuery/order/all',
        },
        name: 'orderQueryDeatil',
        component: () =>
          import('@/views/business/order/orderQuery/order/tenant/detail'),
      },
      {
        path: 'rwork/ruleConfig/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=规则配置详情;en-US=ruleConfig Detail',
          goBackRouter: '/rwork/ruleConfig',
        },
        name: 'ruleConfigDetail',
        component: () => import('@/views/business/base/ruleConfig/detail.vue'),
      },

      {
        path: 'visualization/list',
        name: 'VlinkVisualizationList',
        component: () =>
          import('@/views/ruge/vlink/visualization/indexComponent'),
      },
      {
        path: 'material/list',
        name: 'VlinkMaterialList',
        component: () => import('@/views/ruge/vlink/material/indexComponent'),
      },
      {
        path: 'visualization/detail',
        name: 'VlinkVisualizationDetail',
        component: () =>
          import('@/views/ruge/vlink/visualization/detail/index.vue'),
      },
    ],
  },
  // bms
  {
    path: '/bms',
    component: Layout,
    redirect: '/bms/informationDelivery/serviceGuide',
    children: [
      {
        path: 'commonDashboard',
        name: 'BMSCommonDashboard',
        component: () => import('@/views/ruge/bms/commonDashboard'),
      },
      // bms - new - dashboard
      {
        path: 'dashboard',
        name: 'BMSDashboard',
        component: () => import('@/views/ruge/bms/bmsNew/dashboard'),
      },
      {
        path: 'customDashboard',
        name: 'VlinkCustomDashboard',
        component: () =>
          import('@/views/ruge/vlink/dashboardEdit/page/dashboard.vue'),
      },
      // 指标管理
      {
        path: 'indicatorManagement',
        name: 'indicatorManagement',
        component: () =>
          import('@/views/ruge/bms/indicatorManagement/index.vue'),
      },
      // 指标新增/编辑
      {
        path: 'indicatorEdit',
        name: 'indicatorEdit',
        component: () => import('@/views/ruge/bms/indicatorEdit/index.vue'),
      },
      // 指标新增/编辑
      {
        path: 'indicatorReleaseRecord',
        name: 'indicatorReleaseRecord',
        component: () =>
          import('@/views/ruge/bms/indicatorReleaseRecord/index.vue'),
      },
      // 模板管理
      {
        path: 'templateManagement',
        name: 'templateManagement',
        component: () =>
          import('@/views/ruge/bms/templateManagement/index.vue'),
      },
      // 模板编辑
      {
        path: 'templateEdit',
        name: 'templateDetailEdit',
        component: () => import('@/views/ruge/bms/templateEdit/index.vue'),
      },
      {
        path: 'dashboardEdit',
        name: 'VlinkDashboardEdit',
        component: () => import('@/views/ruge/vlink/dashboardEdit'),
      },
      // bms - new - workbench
      {
        path: 'workbench',
        name: 'BMSWorkbench',
        component: () => import('@/views/ruge/bms/bmsNew/workbench'),
      },
      // bms - new - 三项电表
      {
        path: 'electricityMeter',
        name: 'BMSelectricityMeter',
        component: () => import('@/views/ruge/bms/bmsNew/electricityMeter'),
      },
      // bms - new - 园区每月用水统计
      {
        path: 'monthlyWaterUsage',
        name: 'BMSmonthlyWaterUsage',
        component: () => import('@/views/ruge/bms/bmsNew/monthlyWaterUsage'),
      },
      // bms - new - 园区每月用电统计
      {
        path: 'monthlyElectricUsage',
        name: 'BMSmonthlyElectricUsage',
        component: () => import('@/views/ruge/bms/bmsNew/monthlyElectricUsage'),
      },
      // bms - new - 园区租户用电情况
      {
        path: 'electricUsageSituation',
        name: 'BMSelectricUsageSituation',
        component: () =>
          import('@/views/ruge/bms/bmsNew/electricUsageSituation'),
      },
      // bms - new - 园区租户用水情况
      {
        path: 'waterUsageSituation',
        name: 'BMSElecricityConsumption',
        component: () => import('@/views/ruge/bms/bmsNew/waterUsageSituation'),
      },
      // bms - new - 锦绣三期用电情况统计
      {
        path: 'elecricityConsumption',
        name: 'BMSwaterUsageSituation',
        component: () =>
          import('@/views/ruge/bms/bmsNew/electricityConsumption'),
      },
      // 园区办事 - 办事指南
      {
        path: 'informationDelivery/serviceGuide',
        name: 'BMSServiceGuide',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/informationDelivery/serviceGuide'
          ),
      },
      // 园区办事 - 投诉建议
      {
        path: 'parkAffairs/suggestions',
        name: 'BMSSuggestions',
        component: () =>
          import('@/views/ruge/bms/customerService/parkAffairs/suggestions'),
      },
      // 园区办事 - 发票开具记录
      {
        path: 'paymentInvoice/invoiceRecord',
        name: 'BMSInvoiceRecord',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/paymentInvoice/invoiceRecord'
          ),
      },

      // 园区办事 - 缴费记录
      {
        path: 'paymentInvoice/paymentRecord',
        name: 'BMSPaymentRecord',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/paymentInvoice/paymentRecord'
          ),
      },
      // 园区办事 - 有偿工单记录
      {
        path: 'paymentInvoice/paidWorkOrder',
        name: 'BMSPaidWorkOrder',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/paymentInvoice/paidWorkOrder'
          ),
      },
      // 园区办事 - 有偿工单详情
      {
        path: 'paymentInvoice/paidWorkOrder/paidWorkDetail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=有偿工单详情;en-US=Paid Work Order Details',
          goBackRouter: '/bms/paymentInvoice/paidWorkOrder',
        },
        name: 'PaidWorkDetail',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/paymentInvoice/paidWorkOrder/paidWorkDetail.vue'
          ),
      },
      // 园区办事 - 装修指引
      {
        path: 'parkAffairs/decorationGuidelines',
        name: 'BMSDecorationGuidelines',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/parkAffairs/decorationGuidelines'
          ),
      },
      // 园区办事 - 用户评价
      {
        path: 'parkAffairs/userEvaluation',
        name: 'BMSUserEvaluation',
        component: () =>
          import('@/views/ruge/bms/customerService/parkAffairs/userEvaluation'),
      },
      // 园区办事 - 物品放行审核
      {
        path: 'parkAffairs/releaseItems',
        name: 'BMSReleaseItems',
        component: () =>
          import('@/views/ruge/bms/customerService/parkAffairs/releaseItems'),
      },
      // 园区办事- 二维码生成记录
      {
        path: 'parkAffairs/qrCodeRecord',
        name: 'BMSUserQrCodeRecord',
        component: () =>
          import('@/views/ruge/bms/customerService/parkAffairs/qrCodeRecord'),
      },
      // 信息发布 - 园区公告
      {
        path: 'informationDelivery/parkNotice',
        name: 'BMSQrCodeRecord',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/informationDelivery/parkNotice'
          ),
      },
      // 信息发布 - banner配置
      {
        path: 'informationDelivery/bannerConfig',
        name: 'BMSBannerConfig',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/informationDelivery/bannerConfig'
          ),
      },
      // 信息发布 -广告配置
      {
        path: 'informationDelivery/advertisement',
        name: 'BMSAdvertisement',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/informationDelivery/advertisement'
          ),
      },
      // 信息发布 - 园区活动
      {
        path: 'informationDelivery/parkActivity',
        name: 'BMSparkActivity',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/informationDelivery/parkActivity'
          ),
      },
      // 信息发布 -开票失败列表
      {
        path: 'informationDelivery/invoicingFailure',
        name: 'BMSparkInvoicingFailure',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/informationDelivery/invoicingFailure'
          ),
      },
      // 信息发布 - 入园企业
      {
        path: 'informationDelivery/parkEnterprise',
        name: 'BMSparkEnterprise',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/informationDelivery/parkEnterprise'
          ),
      },
      // 万物 - 地点管理
      {
        path: 'wanwu/locationManagement',
        name: 'wanwuLocationManagement',
        component: () => import('@/views/ruge/bms/wanwu/locationManagement'),
      },
      // 万物 - 站点管理
      {
        path: 'wanwu/siteManagement',
        name: 'wanwuSiteManagement',
        component: () => import('@/views/ruge/bms/wanwu/siteManagement'),
      },
      // 万物 - 历史记录
      {
        path: 'wanwu/historicalRecord',
        name: 'wanwuHistoricalRecord',
        component: () => import('@/views/ruge/bms/wanwu/historicalRecord'),
      },
      // 综合产业管理平台
      {
        path: 'workingStaging',
        name: 'bmsSorkingStaging',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/workingStaging'
          ),
      },
      {
        path: 'settingCenter',
        name: 'bmsSettingCenter',
        component: () =>
          import('@/views/ruge/bms/integratedManagementPlatform/settingCenter'),
      },
      {
        path: 'SOPManagement',
        name: 'SOPManagement',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/SOPManagement/index.vue'
          ),
      },
      {
        path: 'financialStatistics',
        name: 'bmsFinancialStatistics',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/finance/financialStatistics'
          ),
      },
      {
        path: 'rentStatistics',
        name: 'bmsRentStatistics',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/rent/rentStatistics'
          ),
      },
      {
        path: 'propertyStatistics',
        name: 'bmsPropertyStatistics',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/property/propertyStatistics'
          ),
      },
      {
        path: 'WaterEnergyConsumption',
        name: 'bmsWaterEnergyConsumption',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/WaterEnergyConsumption'
          ),
      },
      {
        path: 'TenantWaterStatistics',
        name: 'bmsTenantWaterStatistics',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/TenantWaterStatistics'
          ),
      },
      {
        path: 'TenantElectricityStatistics',
        name: 'bmsTenantElectricityStatistics',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/TenantElectricityStatistics'
          ),
      },
      {
        path: 'TenantWaterSituation',
        name: 'bmsTenantWaterSituation',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/TenantWaterSituation'
          ),
      },
      {
        path: 'TenantElectricitySituation',
        name: 'bmsTenantElectricitySituation',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/TenantElectricitySituation'
          ),
      },
      {
        path: 'ElectricEnergyConsumption',
        name: 'bmsElectricEnergyConsumption',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/ElectricEnergyConsumption'
          ),
      },
      {
        path: 'ComprehensiveAnalysisBoard',
        name: 'bmsComprehensiveAnalysisBoard',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/ComprehensiveAnalysisBoard'
          ),
      },
      {
        path: 'OrderInspectionVisualization',
        name: 'bmsOrderInspectionVisualization',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/OrderInspectionVisualization'
          ),
      },
      {
        path: 'InspectionVisualization',
        name: 'bmsInspectionVisualization',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/InspectionVisualization'
          ),
      },
      {
        path: 'orderList',
        name: 'bmsOrderList',
        component: () =>
          import('@/views/business/order/orderQuery/order/tenant/all'),
      },
      {
        path: 'orderDetail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=工单详情;en-US=Order Detail',
          goBackRouter: '/bms/orderList',
        },
        name: 'orderDeatil',
        component: () =>
          import('@/views/business/order/orderQuery/order/tenant/detail'),
      },
      {
        path: 'inspectionList',
        name: 'bmsInspectionList',
        component: () =>
          import('@/views/business/inspection/query/tenant/inspectionQuery'),
      },
      {
        path: 'inspectionDetail',
        // 如果是详情页，需要配置detail:true
        name: 'bmsInspectionDetail',
        meta: {
          detail: true,
          name: 'zh-CN=巡检详情;en-US=Inspection Detail',
          goBackRouter: '/bms/inspectionList',
        },
        component: () =>
          import('@/views/business/inspection/query/tenant/inspectionDetail'),
      },
      {
        path: 'resourceStatistics',
        name: 'bmsResourceStatistics',
        component: () =>
          import(
            '@/views/ruge/bms/integratedManagementPlatform/resources/resourceStatistics'
          ),
      },
      // 缴费与发票 - 加班空调记录
      {
        path: 'customerService/airtimeRecord',
        name: 'BMSairtimeRecord',
        component: () =>
          import('@/views/ruge/bms/customerService/airtimeRecord'),
      },
      // 缴费与发票 - 加班空调详情
      {
        path: 'customerService/airtimeRecord/airtimeDetail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=加班空调详情;en-US=Paid Work Order Details',
          goBackRouter: '/bms/customerService/airtimeRecord',
        },
        name: 'airTimeDetail',
        component: () =>
          import(
            '@/views/ruge/bms/customerService/airtimeRecord/airtimeDetail.vue'
          ),
      },
      // 园区办事 - 货梯使用
      {
        path: 'customerService/freightElevators',
        name: 'BMSfreightElevators',
        component: () =>
          import('@/views/ruge/bms/customerService/freightElevators'),
      },
    ],
  },
  {
    path: '/gsPark',
    component: Layout,
    redirect: '/gsPark/parkAffairs/serviceGuide',
    children: [
      // 园区办事 - 办事指南
      {
        path: 'informationDelivery/serviceGuide',
        name: 'BMSgsParkServiceGuide',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/informationDelivery/serviceGuide'
          ),
      },
      // 园区办事 - 投诉建议
      {
        path: 'parkAffairs/suggestions',
        name: 'BMSgsParkSuggestions',
        component: () =>
          import('@/views/ruge/gsPark/customerService/parkAffairs/suggestions'),
      },
      // 园区办事 - 发票开具记录
      {
        path: 'paymentInvoice/invoiceRecord',
        name: 'BMSgsParkInvoiceRecord',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/paymentInvoice/invoiceRecord'
          ),
      },
      // 园区办事 - 充电扣费记录
      {
        path: 'paymentInvoice/chargPayment',
        name: 'BMSgsParkChargPayment',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/paymentInvoice/chargPayment'
          ),
      },
      // 园区办事 - 有偿工单记录
      {
        path: 'paymentInvoice/paidWorkOrder',
        name: 'BMSgsParkPaidWorkOrder',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/paymentInvoice/paidWorkOrder'
          ),
      },
      // 园区办事 - 有偿工单详情
      {
        path: 'paymentInvoice/paidWorkOrder/paidWorkDetail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=有偿工单详情;en-US=Paid Work Order Details',
          goBackRouter: '/gsPark/paymentInvoice/paidWorkOrder',
        },
        name: 'gsParkPaidWorkDetail',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/paymentInvoice/paidWorkOrder/paidWorkDetail.vue'
          ),
      },

      // 园区办事 - 缴费记录
      {
        path: 'paymentInvoice/paymentRecord',
        name: 'BMSgsParkPaymentRecord',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/paymentInvoice/paymentRecord'
          ),
      },
      // 园区办事 - 装修指引
      {
        path: 'informationDelivery/decorationGuidelines',
        name: 'BMSgsParkDecorationGuidelines',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/informationDelivery/decorationGuidelines'
          ),
      },
      // 园区办事 - 用户评价
      {
        path: 'parkAffairs/userEvaluation',
        name: 'BMSgsParkUserEvaluation',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/parkAffairs/userEvaluation'
          ),
      },
      // 园区办事 - 地面行车审核
      {
        path: 'parkAffairs/groundRunReview',
        name: 'BMSgsParkGroundRunReview',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/parkAffairs/groundRunReview'
          ),
      },
      // 园区办事 - 货梯使用
      {
        path: 'parkAffairs/freightElevators',
        name: 'BMSgsParkFreightElevators',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/parkAffairs/freightElevators'
          ),
      },
      // // 园区办事 - 空调加时
      {
        path: 'parkAffairs/airConOvertime',
        name: 'BMSgsParkAirConOvertime',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/parkAffairs/airConOvertime'
          ),
      },
      // // 园区办事 - 物品放行
      {
        path: 'parkAffairs/releaseItems',
        name: 'BMSgsParkReleaseItems',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/parkAffairs/releaseItems'
          ),
      },
      // 园区办事- 二维码生成记录
      {
        path: 'parkAffairs/qrCodeRecord',
        name: 'BMSgsParkUserQrCodeRecord',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/parkAffairs/qrCodeRecord'
          ),
      },
      // 园区办事- 停车月卡申请
      {
        path: 'parkAffairs/monthlyCardApplication',
        name: 'BMSgsParkMonthlyCardApplication',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/parkAffairs/monthlyCardApplication'
          ),
      },
      // 园区办事- 停车月卡申请 - 查看详情
      {
        path: 'parkAffairs/monthlyCardApplication/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=停车月卡申请;en-US=parkAffairs MonthlyCardApplication',
          goBackRouter: '/gsPark/parkAffairs/monthlyCardApplication',
        },
        name: 'gsParkParkingDetails',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/parkAffairs/monthlyCardApplication/detail.vue'
          ),
      },
      // 信息发布 - 园区公告
      {
        path: 'informationDelivery/parkNotice',
        name: 'BMSgsParkQrCodeRecord',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/informationDelivery/parkNotice'
          ),
      },
      // 信息发布 -开票失败列表
      {
        path: 'informationDelivery/invoicingFailure',
        name: 'BMSgsParkInvoicingFailure',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/informationDelivery/invoicingFailure'
          ),
      },
      // 信息发布 - banner配置
      {
        path: 'informationDelivery/bannerConfig',
        name: 'BMgsParkSparkNotice',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/informationDelivery/bannerConfig'
          ),
      },
      // 信息发布 - 园区活动
      {
        path: 'informationDelivery/parkActivity',
        name: 'BMgsParkSparkActivity',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/informationDelivery/parkActivity'
          ),
      },
      // 信息发布 - 入园企业
      {
        path: 'informationDelivery/parkEnterprise',
        name: 'BMSgsParkparkEnterprise',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/informationDelivery/parkEnterprise'
          ),
      },
      // 信息发布 - 活动报名
      {
        path: 'informationDelivery/eventregistration',
        name: 'BMSgsParkeventregistration',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/informationDelivery/eventregistration'
          ),
      },
      // 体育场 - 场地预定
      {
        path: 'stadium/vReservation',
        name: 'BMSgsParkparkstadium',
        component: () =>
          import('@/views/ruge/gsPark/customerService/stadium/vReservation'),
      },
      // 体育场 - 场地日历
      {
        path: 'stadium/vCalendar',
        name: 'BMSgsParkparkCalendar',
        component: () =>
          import('@/views/ruge/gsPark/customerService/stadium/vCalendar'),
      },
      // 体育场 - 次卡配置
      {
        path: 'stadium/vConfig',
        name: 'BMSgsParkparkvConfig',
        component: () =>
          import('@/views/ruge/gsPark/customerService/stadium/vConfig'),
      },
      // 体育场 - 场地配置
      {
        path: 'stadium/vSiteConfig',
        name: 'BMSgsParkparkvSiteConfig',
        component: () =>
          import('@/views/ruge/gsPark/customerService/stadium/vSiteConfig'),
      },
      // 信息发布 - 活动报名 -报名详情
      {
        path: 'informationDelivery/eventregistration/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=活动详情;en-US=informationDelivery Eventregistration',
          goBackRouter: '/gsPark/informationDelivery/eventregistration',
        },
        name: 'gsParkEventregistrationDetails',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/informationDelivery/eventregistration/detail.vue'
          ),
      },
      // 客服坐席记录 - 工单事件
      {
        path: 'customerSeats/workOrderEvent',
        name: 'gsParkWorkOrderEvent',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerSeats/workOrderEvent'
          ),
      },
      {
        path: 'customerSeats/workOrderEvent/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=详情页;en-US=Work Order Details',
          goBackRouter: '/gsPark/customerSeats/workOrderEvent',
        },
        name: 'gsParkactivityRecordDetails',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerSeats/workOrderEvent/detail.vue'
          ),
      },
      // 会议室 - 会议预定配置
      {
        path: 'MeetingRoom/ReservationConfigura',
        name: 'BMSgsParkparkReservationConfigura',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/MeetingRoom/ReservationConfigura'
          ),
      },
      // 会议室 - 会议列表
      {
        path: 'MeetingRoom/ReservationList',
        name: 'BMSgsParkparkReservationList',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/MeetingRoom/ReservationList'
          ),
      },
      // 会议室 - 会议日历
      {
        path: 'MeetingRoom/ReservationCalendar',
        name: 'BMSgsParkparkReservationCalendar',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/MeetingRoom/ReservationCalendar'
          ),
      },
      // 客户关系管理 - 客户列表
      {
        path: 'customerRelaManagement/customerList',
        name: 'BMSparkcustomerList',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/customerList'
          ),
      },
      // 客户关系管理 - 详情
      {
        path: 'customerRelaManagement/customerList/customerListDetail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=客户列表;en-US=Paid Work Order Details',
          goBackRouter: '/gsPark/customerRelaManagement/customerList',
        },
        name: 'PaidWorkDetail',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/customerList/customerListDetail.vue'
          ),
      },
      // 客户关系管理 - 角色管理
      {
        path: 'customerRelaManagement/rolemanager',
        name: 'BMSparkrolemanager',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/rolemanager'
          ),
      },
      // 客户关系管理 - 人员管理
      {
        path: 'customerRelaManagement/personnelmanager',
        name: 'BMSparkpersonnelmanager',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/personnelmanager'
          ),
      },
      // 客户关系管理 - 公海池
      {
        path: 'customerRelaManagement/highseas',
        name: 'BMSparkhighseas',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/highseas'
          ),
      },
      // 客户关系管理 - 公海池详情
      {
        path: 'customerRelaManagement/highseas/highseasView',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=公海池列表;en-US=Paid Work Order Details',
          goBackRouter: '/gsPark/customerRelaManagement/highseas',
        },
        name: 'PaidWorkDetail',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/highseas/highseasView.vue'
          ),
      }, // 客户关系管理 - 房源销控管理
      {
        path: 'customerRelaManagement/propertySales',
        name: 'BMSparkPropertySales',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/propertySales'
          ),
      }, // 客户关系管理 - - 房源销控管理详情
      {
        path: 'customerRelaManagement/propertySales/detail', // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=销控管理详情;en-US=BMSparkPropertySales',
          goBackRouter: '/gsPark/customerRelaManagement/propertySales',
        },
        name: 'propertySalesDetail',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/propertySales/detail.vue'
          ),
      }, // 客户关系管理 - 房源项目模版
      {
        path: 'customerRelaManagement/projectTemplate',
        name: 'BMSparkProjectTemplate',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/projectTemplate'
          ),
      },
      {
        path: 'customerRelaManagement/projectTemplate/detail', // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=销控管理详情;en-US=BMSparkProjectTemplate',
          goBackRouter: '/gsPark/customerRelaManagement/projectTemplate',
        },
        name: 'projectTemplateDetail',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/projectTemplate/detail.vue'
          ),
      },
      // 客户关系管理 - 客户线索
      {
        path: 'customerRelaManagement/customerLeads',
        name: 'BMSparkcustomerLeads',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/customerLeads'
          ),
      },
      // 客户关系管理 - 数据查重
      {
        path: 'customerRelaManagement/customerLeads/customerDataRepeat',
        name: 'BMSparkcustomerDataRepeat',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/customerLeads/customerDataRepeat'
          ),
      },
      // 客户关系管理 - 线索详情
      {
        path: 'customerRelaManagement/customerLeads/customerLeadsView',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=线索管理;en-US=Paid Work Order Details',
          goBackRouter: '/gsPark/customerRelaManagement/customerLeads',
        },
        name: 'PaidWorkDetail',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/customerLeads/customerLeadsView.vue'
          ),
      },
      // 客户关系管理 - 审批
      {
        path: 'customerRelaManagement/processList',
        name: 'BMSparkcustomerDataRepeat',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/processList'
          ),
      },
      // 客户关系管理 - 渠道管理
      {
        path: 'customerRelaManagement/channelList',
        name: 'BMSparkcustomerDataRepeat',
        component: () =>
          import(
            '@/views/ruge/gsPark/customerService/customerRelaManagement/channelList'
          ),
      },
    ],
  },
  // 金威
  {
    path: '/jwPark',
    component: Layout,
    redirect: '/jwPark/informationModule/serviceGuide',
    children: [
      // 信息发布 - 园区活动
      {
        path: 'informationModule/parkActivity',
        name: 'BMSjwParkparkActivity',
        component: () =>
          import('@/views/ruge/jwPark/informationModule/parkActivity'),
      },
      // 信息发布 - banner配置
      {
        path: 'informationModule/bannerConfig',
        name: 'BMSjwParkBannerConfig',
        component: () =>
          import('@/views/ruge/jwPark/informationModule/bannerConfig'),
      },
      // 信息发布 - 办事指南
      {
        path: 'informationModule/serviceGuide',
        name: 'BMSjwParkServiceGuide',
        component: () =>
          import('@/views/ruge/jwPark/informationModule/serviceGuide'),
      },
      // 信息发布 - 园区公告
      {
        path: 'informationModule/parkNotice',
        name: 'BMSjwParkQrCodeRecord',
        component: () =>
          import('@/views/ruge/jwPark/informationModule/parkNotice'),
      },
      // 信息发布 - 企业黄页
      {
        path: 'informationModule/serviceRecord',
        name: 'BMSjwParkQrserviceRecord',
        component: () =>
          import('@/views/ruge/jwPark/informationModule/serviceRecord'),
      },
      // 园区服务 - 用户评价
      {
        path: 'parkServerModule/userEvaluation',
        name: 'BMSjwParkUserEvaluation',
        component: () =>
          import('@/views/ruge/jwPark/parkServerModule/userEvaluation'),
      },
      // 园区办事 - 缴费记录
      {
        path: 'paymentInvoice/paymentRecord',
        name: 'JWParkPaymentRecord',
        component: () =>
          import('@/views/ruge/jwPark/paymentInvoice/paymentRecord'),
      },
      // 园区办事 -发票开具说明
      {
        path: 'paymentInvoice/invoiceRecord',
        name: 'JWParkInvoiceRecord',
        component: () =>
          import('@/views/ruge/jwPark/paymentInvoice/invoiceRecord'),
      },
      // 园区办事 -开票失败记录
      {
        path: 'paymentInvoice/invoicingFailure',
        name: 'JWParkInvoicingFailure',
        component: () =>
          import('@/views/ruge/jwPark/paymentInvoice/invoicingFailure'),
      },
      // 园区办事 -有偿工单记录
      {
        path: 'paymentInvoice/paidWorkOrder',
        name: 'JWParkPaidWorkOrder',
        component: () =>
          import('@/views/ruge/jwPark/paymentInvoice/paidWorkOrder'),
      },
      // 园区办事 - 投诉建议
      {
        path: 'parkServerModule/suggestions',
        name: 'BMSjwParkSuggestions',
        component: () =>
          import('@/views/ruge/jwPark/parkServerModule/suggestions'),
      },
      // 缴费与发票 - 加班空调记录
      {
        path: 'paymentInvoice/airtimeRecord',
        name: 'BMSjwParkairtimeRecord',
        component: () =>
          import('@/views/ruge/jwPark/paymentInvoice/airtimeRecord'),
      },
      // 缴费与发票 - 加班空调详情
      {
        path: 'paymentInvoice/airtimeRecord/airtimeDetail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=加班空调详情;en-US=Paid Work Order Details',
          goBackRouter: '/jwPark/paymentInvoice/airtimeRecord',
        },
        name: 'jwParkAirTimeDetail',
        component: () =>
          import(
            '@/views/ruge/jwPark/paymentInvoice/airtimeRecord/airtimeDetail.vue'
          ),
      },
      // 会议室 - 会议预定配置
      {
        path: 'MeetingRoom/ReservationConfigura',
        name: 'BMSparkReservationConfigura',
        component: () =>
          import('@/views/ruge/jwPark/MeetingRoom/ReservationConfigura'),
      },
      // 会议室 - 会议列表
      {
        path: 'MeetingRoom/ReservationList',
        name: 'BMSparkReservationList',
        component: () =>
          import('@/views/ruge/jwPark/MeetingRoom/ReservationList'),
      },
      // 会议室 - 会议日历
      {
        path: 'MeetingRoom/ReservationCalendar',
        name: 'BMSparkReservationCalendar',
        component: () =>
          import('@/views/ruge/jwPark/MeetingRoom/ReservationCalendar'),
      },
    ],
  },
  {
    path: '/approval-process/detail',
    name: 'approvalProcessDetail',
    component: () => import('@/views/ruge/approvalProcess/detail'),
  },
];

export default rugeRouter;
