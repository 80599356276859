import Layout from '@/views/ruge/layout/Layout';
const amsRouter = [
    {
        path: '/ams',
        component: Layout,
        redirect: '/ams/basicConfig/codeConfig',
        children:[
            {
                path: 'basicConfig/codeConfig',
                name: 'AMSCodeConfig',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/codeConfig'
                  ),
              },
              {
                path: 'basicConfig/warehouse',
                name: 'AMSWarehouse',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/warehouse'
                  ),
              },
              {
                path: 'basicConfig/warnRules',
                name: 'AMSWarnRules',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/warnRules'
                  ),
              },
              {
                path: 'basicConfig/depreciationRules',
                name: 'AMSDepreciationRules',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/depreciationRules'
                  ),
              },
              {
                path: 'basicConfig/tabCodeConfig',
                name: 'AMSTabCodeConfig',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/tabCodeConfig'
                  ),
              },
              {
                path: 'basicConfig/assetCodeRules',
                name: 'AMSAssetCodeRules',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/assetCodeRules'
                  ),
              },
              {
                path: 'basicConfig/fixedAssetCategory',
                name: 'AMSFixedAssetCategory',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/fixedAssetCategory'
                  ),
              },
              {
                path: 'basicConfig/consumablesCategory',
                name: 'AMSConsumablesCategory',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/consumablesCategory'
                  ),
              },
              {
                path: 'basicConfig/materialCategory',
                name: 'AMSMaterialCategory',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/materialCategory'
                  ),
              },
              {
                path: 'basicConfig/paramsTemplate',
                name: 'AMSParamsTemplate',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/paramsTemplate'
                  ),
              },
            {
                path: 'asset-management',
                component: () => import('@/views/ruge/ams/management/index.vue'),
                children: [
                    {
                        name: 'AMSAssetLedgerList',
                        path: 'ledger-list',
                        component: () => import("@/views/ruge/ams/management/asset/list/index.vue")
                    },
                    {
                        name: 'AMSAssetLedgerAdd',
                        path: 'ledger-add',
                        component: () => import("@/views/ruge/ams/management/asset/add/index.vue")
                    },
                    {
                        name: 'AMSAssetLedgerDetail',
                        path: 'ledger-detail',
                        component: () => import("@/views/ruge/ams/management/asset/detail/index.vue")
                    }
                ]
            }
        ]
    }
]
export default amsRouter;