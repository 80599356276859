<template>
	<div>
		<!--顶部右侧铃铛消息提示-->
		<div class="el-badge-container" @click="drawerClick">
			<el-badge :hidden="difAllNums === 0" :value="difAllNums" :max="99">
				<img src="../../assets/images/menu_message_icon.png" alt="" />
			 	<!--<i class="el-icon-bell right-menu-icon" @click="drawerClick" :style="{ color: $store.state.app.displayLayout === 1 ? '#2a4158' : '#b4c5d5'}"></i>--> 
			</el-badge>
		</div>
		<el-drawer style="line-height: 16px" :visible.sync="drawer" :before-close="()=>{drawer = false}" :direction="direction" :size="400" :with-header="false">
			<!--<template slot="title">
				<span><title-icon />{{ $t("personal.setting9") }} 123</span>
			</template>-->
			<div class="drawBody">
		  		<div class="tabs_warp">
			  		<div class="it_tap" v-for="(it,dex) in tabsArr" :key='dex' :class="{'actived':activeTabs==it.val}">
			  			<div  class="it_n" @click='changeTabs(it)'>
			  				{{ it.name }}<span class="_nval" v-if="it.nums">{{it.nums}}</span>
			  				<div class="xbtm"></div>
			  			</div>
			  		</div>
			  		<!--<div class="closeIcon" @click="drawer = false"> × </div>-->
			  	</div>
			  	<div class="waitHandle"  v-if="activeTabs==1" >
			  		<div class="listBox">
				  		<ul>
				  			<li class="itli" v-for="(it,dex) in waitHandArrs" :class="{txGray:it.isRead=='Y'}" @click.stop="handleItMes(it,'handle')">
				  				<span class="round" :class="'spanColor'+it.urgencyLevel"></span>
				  				<span class="status isUrge" v-if="it.isUrging=='Y'">催办</span>
				  				<span class="status">{{it.systemName}}</span>
				  				<span class="content" :class="{wid75:it.isUrging=='Y'}">
				  					{{it.exmTitle}}
				  					</br>
				  					<span class="txtime">{{common.getTimeMmss(it.lastUpdateDate)}}</span>
				  				</span>
				  				
				  			</li>
				  		</ul>
				  	</div>
				  	<div class="lookAll"  @click.stop="jumpAllHtml('handle')"> 查看所有待办 <i class="el-icon-arrow-right" /> </div>
			  	</div>
			  	<div class="waitHandle"  v-if="activeTabs==3" >
			  		<div class="listBox">
				  		<ul >
				  			<li class="itli" v-for="(it,dex) in waitLookArrs" :class="{txGray:it.isRead=='Y'}" @click.stop="handleItMes(it,'read')">
				  				<span class="round"></span>
				  				<!--<span class="status" >{{it.urgencyLevelStr}}</span>-->
				  				<span class="content lookWarp">
				  					{{it.redTitle}}
				  					</br>
				  					<span class="txtime">{{common.getTimeMmss(it.releaseDate)}}</span>
				  				</span>
				  			</li>
				  		</ul>
				  	</div>
				  	<div class="lookAll" @click.stop="jumpAllHtml('read')"> 查看所有待阅  <i class="el-icon-arrow-right" /></div>
			  	</div>
			  	
				<div class="myMsgs"  v-if="activeTabs==2">
					<template v-if="notifyList.length > 0">
						<div class="data-list">
							<el-row v-for="(item, index) in notifyList" class="data-row" :key="index">
								<el-col :span="2" :offset="1" style="margin: 14px 10px 0px 16px">
									<el-avatar :size="50" :src="getAvatar(item)">
										<img :src="defaultAvatar" />
									</el-avatar>
								</el-col>
								<el-col :span="20">
									<span class="notify-sender"> {{ item.senderName }} </span>
									<span class="notify-time">
						                {{ item.notifyTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}
					              	</span>
									<el-button type="text" @click="removeNotify(item)">
										<i class="el-icon-delete delete-btn"></i>
									</el-button>
									<div @click="searchNotifyDetails(item)">
										<el-link class="notify-info" v-bind:class="{ unread: item.readStatus == 1 }" :underline="false">{{ item.notifyTitle }}
										</el-link>
									</div>
								</el-col>
							</el-row>
						</div>
					</template>
					<template v-else>
						<div class="no-data">{{ $t("commons.noData") }}</div>
					</template>
					<el-button-group class="notify-btn-group">
						<el-button class="notify-btn" @click="showMore">
							{{ $t("commons.more") }}
						</el-button>
						<el-button class="notify-btn" @click="clearALL">
							{{ $t("commons.clearAll") }}
						</el-button>
					</el-button-group>
				</div>
				
				
		  	</div>
		</el-drawer>

		<information-details :detailDrawer.sync="detailDrawer" :informationInfo="informationInfo" />
	</div>
</template>

<script>
	import {
		getRecordInformation,
		updateNotifySignRead,
		logicDeleteRecord,
		clearAllRecord,
		getRecordUnreadCount,
	} from "@/api/ruge/notify/notify";
	import InformationDetails from "./components/informationDetails";
	import { envInfo } from "@/constants/envInfo";

	export default {
		name: "Information",
		components: {
			InformationDetails
		},
		computed: {
			difAllNums(){
				let num=0;
				this.tabsArr.map(item=>num=num+item.nums);
				return num
			}
		},
		data() {
			return {
				// 默认头像
				defaultAvatar: require("@/assets/images/defaultAvatar.png"),
				drawer: false,
				direction: "rtl",
				notifyList: [],
				listLoading: false,
				detailDrawer: false,
				informationInfo: null,
				unreadCount: 0,
				
				activeTabs:'1',
				tabsArr:[
					{name:'我的待办',val:'1',nums:0},
					{name:'我的待阅',val:'3',nums:0},
					{name:'我的消息',val:'2',nums:0},
				],
				waitHandArrs:[],//待办
				waitLookArrs:[],//待阅
			};
		},
		watch: {
			drawer: {
				handler(val) {
					this.changeHandler(val);
					this.$emit("messageChange", val);
				},
				immediate: true,
			},
		},
		created() {
			this.getRecordUnreadCount();
			this.initWaitHandlist();
			this.initWaitLooklist();
		},
		methods: {
			jumpAllHtml(type){
				let jumpUrl;
				if(type=='handle'){
					jumpUrl='/approval-process/pendingReview';
				}
				if(type=='read'){
					jumpUrl='/approval-process/myReadTable';
				}
				if(!jumpUrl) return;
				this.drawer = false;
				let params = {
					path: jumpUrl,
					query: {},
				}
				this.$router.push(params)
				
				
			},
			async handleItMes(row,type){
				//待办|待阅单行点击  变成已读或者已处理  type=handle || read
				let  urlByType=type=='handle'?'examine':'read';
				let rqData={
					"isRead": "Y", 
					"exmUserAccount": row.exmUserAccount,
				}
				if(type=='handle'){
					rqData.exmId=row.exmId
				}
				if(type=='read'){
					rqData.redId=row.redId
					rqData.redUserAccount=row.redUserAccount;
				}
				//https://sit.rlinkiot.com/saascloud/saas/workflow/examine/read  //待办消息修改为已读
				//https://sit.rlinkiot.com/saascloud/saas/workflow/read/read//待阅消息修改为已读
				let res =await this.ApiHttp(`/workflow/${urlByType}/read`,rqData,'post');
				console.log(99999999999,res)
				if(res){
//					this.drawer = false;
					if(type=='handle'){
						this.initWaitHandlist();//刷新我的待办数据
					}
					if(type=='read'){
						row.exmDetailLink=row.redDetailLink;
						this.initWaitLooklist();//刷新我的待阅数据
					}
					this.jumpToDetails(row);
//					this.initWaitHandlist();//刷新我的待办数据
//					this.initWaitLooklist();//刷新我的待阅数据
				}
				
			},
			async jumpToDetails(row) {
				console.log(888888888888,row)
				const { systemName, exmDetailLink,serviceCode }=row
		      if (systemName === "工单") {
		        const url = `${location.origin}${location.pathname}#/iot/orderQuery/detail?orderId=${exmDetailLink}`;
		        window.open(url, "_blank");
		      } else if (systemName === "巡检") {
		        const url = `${location.origin}${location.pathname}#/tenant/inspection/inspectionSearch/detail?inspectionJobId=${exmDetailLink}`;
		        window.open(url, "_blank");
		      }
//		      else if (systemName === "审核"||systemName === "待阅") {
//		      	let rqData={
//		      		'taskId':exmDetailLink,
//		      	}
//		      	let httpRes=await this.ApiHttp('/workflow/examine/getPageJumpParam',rqData);
//		      	
//		      	console.log('httpRes',httpRes)
//		      	if(!httpRes||!httpRes.taskId){
//		      		console.log('后台未查询出对应数据')
//		      		return;
//		      	}
//		      	const { taskId, definitionId } = httpRes;
//	            let url = `${location.origin}${location.pathname}#/approval-process/detail?definitionId=${definitionId}`;
//	            if (taskId) {
//	              url += `&taskId=${taskId}`;
//	            }
//	            window.open(url, "_blank");
//		      }
		      else if(systemName === "审核"||systemName === "待阅"){
		      	let url = `${location.origin}${location.pathname}#/approval-process/detail?definitionId=${serviceCode}`;
				if (exmDetailLink) {
	              url += `&taskId=${exmDetailLink}`;
	            }            
				window.open(url, "_blank");
		      }
		    },
			async initWaitHandlist(){
				//获取我的待办数据
				let rqData={
					current:'1',
					rowCount:15,
				}
				let res =await this.ApiHttp('/workflow/examine/findExaminePage',rqData);
				console.log('-+-+-+-+-+res',res)
				this.tabsArr.map(it=>{
					if(it.name=='我的待办') it.nums=res.total
				})
				this.waitHandArrs=res.rows||[];
			},
			async initWaitLooklist(){
				//获取我的待阅数据
				let rqData={
					current:'1',
					rowCount:15,
				}
				let res =await this.ApiHttp('/workflow/read/findReadInfoPage',rqData);
				console.log('-+-+-+-+-+res',res)
				const leveArrs=['一般','急','紧急'];
				res.rows.map(it=>{
					it.urgencyLevelStr=leveArrs[it.urgencyLevel];
				})
				this.tabsArr.map(it=>{
					if(it.name=='我的待阅') it.nums=res.total
				})
				this.waitLookArrs=res.rows||[];
				
			},
			changeHandler(val) {
				this.$emit("messageChange", val);
			},
			//点击铃铛，打开侧边栏
			drawerClick() {
				if(!this.drawer) {
					this.drawer = true;
					this.initAllNums();
				}
			},
			changeTabs(it){
				this.activeTabs=it.val;
				if(it.val=='1'){
					this.initWaitHandlist();
				}
				if(it.val=='2'){
					this.getRecordInformation();
				}
				if(it.val=='3'){
					this.initWaitLooklist();
				}
			},
			initAllNums(){
				//获取全部类型的全部数据
				this.getRecordInformation();
				this.initWaitHandlist();
				this.initWaitLooklist();
			},
			getRecordInformation() {
				this.listLoading = true;
				getRecordInformation({
						current: 1,
						rowCount: 20
					})
					.then((response) => {
						this.notifyList = response.rows;
						this.getRecordUnreadCount();
					})
					.catch((error) => {
						this.listLoading = false;
						console.log(`查询失败，原因 => ${error}`);
					});
			},
			//查询通知消息
			searchNotifyDetails(item) {
				console.log("item", item);
				this.informationInfo = item;
				this.detailDrawer = true;
				if(item.readStatus == 1) {
					updateNotifySignRead({
							userAccount: this.$store.getters.userAccount,
							batchNo: item.batchNo,
						})
						.then(() => {
							item.readStatus = 2;
						})
						.then(() => {
							this.getRecordUnreadCount();
						});
				}
			},
			// 查询未读消息数
			getRecordUnreadCount() {
				getRecordUnreadCount()
					.then((response) => {
						this.unreadCount = response;
						this.tabsArr.map(it=>{
							if(it.name=='我的消息') it.nums=this.unreadCount
						})
					})
					.catch((error) => {
						console.log(`查询失败，原因 => ${error}`);
					});
			},
			// 获取用户头像
			getAvatar(item) {
				return envInfo.bgApp.ssoPath + "/param/avatar?u=" + item.sendUserAccount;
			},
			// 删除单个通知
			removeNotify(item) {
				this.$confirm(
						this.$t("message.deleteConfirm"),
						this.$t("commons.warning"), {
							confirmButtonText: this.$t("commons.confirm"),
							cancelButtonText: this.$t("commons.cancel"),
							type: "warning",
						}
					)
					.then(() => {
						logicDeleteRecord({
							notifyRecordId: item.notifyRecordId
						}).then(
							() => {
								// 成功提示
								this.$message({
									message: this.$t("message.deleteSuccess"),
									type: "success",
								});
								this.getRecordInformation();
							}
						);
					})
					.catch((error) => {
						console.log(`未删除，原因 => ${error}`);
					});
			},
			// 查看更多，跳转消息查询页面
			showMore() {
				this.$router.push("/personal/common?id=9");
				this.drawer = false;
			},
			// 清空消息
			clearALL() {
				this.$confirm(
						this.$t("message.deleteConfirm"),
						this.$t("commons.warning"), {
							confirmButtonText: this.$t("commons.confirm"),
							cancelButtonText: this.$t("commons.cancel"),
							type: "warning",
						}
					)
					.then(() => {
						clearAllRecord().then(() => {
							// 成功提示
							this.$message({
								message: this.$t("message.deleteSuccess"),
								type: "success",
							});
							// 清空列表
							this.notifyList = [];
							// 未读数清零
							this.unreadCount = 0;
						});
					})
					.catch((error) => {
						console.log(`未删除，原因 => ${error}`);
					});
			},
		},
	};
</script>

<style scoped>
	.el-badge-container {
		height: 40px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	
	.el-badge {
		display: flex;
		align-items: center;
	}
	
	.el-icon-bell {
		font-size: 18px;
		position: relative;
		top: -1px;
		left: 2px;
		cursor: pointer;
	}
	
	.information-icon>>>svg {
		width: 22px;
		height: 22px;
		cursor: pointer;
		fill: #48576a;
		vertical-align: 11px;
	}
	
	.notify-sender {
		font-size: 14px;
	}
	
	.notify-time {
		font-size: 14px;
		color: #909399;
	}
	
	.notify-info {
		font-size: 15px;
		display: inline-block;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	.unread {
		font-weight: 600;
		color: #303133;
	}
	
	.data-list {
		border-top: 1px solid #f1f3f8;
		height: 800px;
		overflow-y: scroll;
		padding-bottom: 60px;
	}
	
	.data-row {
		padding-bottom: 10px;
		border-bottom: 1px solid #f1f3f8;
	}
	
	.notify-btn-group {
		position: absolute;
		bottom: 0px;
		width: 100%;
	}
	
	.notify-btn {
		margin-top: 10px;
		width: 50%;
		height: 50px;
		justify-content: center;
		border-radius: 0px;
	}
	
	.el-avatar {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
	}
	
	.drawBody{
		/*padding: 0px 20px;*/
		font-size: 14px;
		box-sizing: border-box;
		.tabs_warp{
			margin-top: 16px;
			padding-left: 10px;
			line-height: 48px;
			display: flex;
			position: relative;
			border-bottom: 1px solid #eee;
			.closeIcon{
				position: absolute;
	            font-size: 18px;
		        right: 12px;
		        cursor: pointer;
			}
			.closeIcon:hover{
				color: #F35555;
			}
			.it_tap{
				padding:0px 10px;
				line-height: 48px;
				color:#5D687C;
				cursor: pointer;
				flex: 8;
				max-width: 108px;
				text-align: left;
		        .it_n{
		        	font-size: 14px;
		        	font-weight: bold;
		        	position: relative;
	        	    display: inline-block;
		        	.xbtm{
		        		height: 2px;
		        		width: 20px;
		        		position: absolute;
		        		bottom: -1px;
		        		left: 50%;
		        		margin-left: -10px;
		        		
		        	}
		        	._nval{
	                    position: absolute;
	                    top: 10px;
	                    right: -16px;
	                    background: #F56C6C;
	                    border-radius: 10px;
					    color: #FFF;
					    font-size: 12px;
					    height: 18px;
					    line-height: 18px;
					    padding: 0 5px;
					    text-align: center;
					    white-space: nowrap;
					    border: 1px solid #FFF;
		        	}
		        }
			}
			.it_tap:hover{
				color: #2a61ff !important;
			}
			.actived.it_tap{
				color: #252D3D;
				.xbtm{
					background: #1A4CEC;
				}
				
			}
		}
		.waitHandle{
			height: calc(100vh - 68px);
			.lookAll{
				height: 60px;
				line-height: 60px;
				text-align: center;
				border-top: 1px solid #EFF2F7;
				color: #5D687C;
				cursor: pointer;
			}
			.lookAll:hover{
				color: #2A61FF;
			}
		}
	
	.listBox{
        height: calc(100% - 60px);
        overflow-y: auto;
		padding: 0px 20px;
		.itli:hover{
			color: #2A61FF;
		}
		.itli{
			/*display: flex;*/
			cursor: pointer;
			/*align-items: center;*/
			border-bottom: 1px solid #F1F4F6;
			padding: 20px 0 12px 0;
			min-height: 68px;
			span{
				display: inline-block;
			}
			.round,.status{
				vertical-align: top;
			}
			.round{
				width: 5px;
				height: 5px;
				background: #2A61FF;
				border-radius: 50%;
				margin-right: 4px;
				position: relative;
				top: 8px;
				/*background: #B5BECE;*/
			}
			.status{
				padding:2px 4px;
				background: #ebeef5;
				color: #3b454c;
				min-width: 30px;
				text-align: center;
				margin-right: 4px;
				border-radius: 2px 4px 2px 4px;
				line-height: 16px;
			    transform: scale(0.75);
			    font-size: 12px;
			}
			.content{
				color: #252D3D;
				font-size: 14px;
				max-width: 308px;
				position: relative;
                top: 2px;
				.txtime{
					font-size: 12px;
					color: #B5BECE;
					margin-top: 2px;
				}
			}
			.content.lookWarp{
				max-width: calc(100% - 10px);
			}
			.content.wid75{
				max-width: 272px;
			}
			.status.isUrge{
				/*催办的标签样式*/
				background: #FFEFEF;
				color: #F35555;
			}
			
			/*.round.spanColor0{
				background: #E8EEFF;
				color: #2A61FF;
			}*/
			.txGray .round{
				background: #E8EEFF;
				color: #2A61FF;
			}
			.round.spanColor1{
				background: #FEC021;
				color: #EBB018;
			}
			.round.spanColor2{
				background: #F35555;
				color: #F35555;
			}
			
		}
		.itli.txGray{
			color: #B5BECE;
		}
	}
	}
</style>