import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 项目分页查询
 * @param params
 */
export function getProjectListPage(params) {
  return request({
    url: envInfo.bgApp.basePath + '/tenant/project/page',
    method: 'get',
    params,
  });
}

/**
 * 项目列表查询
 * @param params
 */
export function getProjectList(params) {
  return request({
    url: envInfo.bgApp.basePath + '/tenant/project/list',
    method: 'get',
    params,
  });
}

/**
 * 修改项目
 * @param params
 */
export function updateProjectList(params) {
  return request({
    url: envInfo.bgApp.basePath + '/tenant/project/update',
    method: 'post',
    data: params,
  });
}

//查询单个项目
export function getOwnProject(params) {
  return request({
    url: envInfo.bgApp.basePath + `/tenant/project/find/${params}`,
    method: 'get',
  });
}

/*
 * 新增项目
 * @param params
 */
export function insertProject(params) {
  return request({
    url: envInfo.bgApp.basePath + '/tenant/project/create',
    method: 'post',
    data: params,
  });
}

/**
 * 删除人员
 * @param params
 */
export function deleteProjectList(param) {
  return request({
    url: envInfo.bgApp.basePath + `/tenant/project/delete`,
    method: 'delete',
    data: param,
  });
}

/**
 * 公司查询
 * @param params
 */
export function getCompanyList() {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/company/list',
    method: 'get',
  });
}

/**
 * 所属公司级联面板列表查询
 * @param params
 */
export function getCompanyCascader() {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/company/org/company',
    method: 'get',
  });
}

/**
 * 查询项目标签
 * @param params
 */
export function getProjectLabel() {
  return request({
    url:
      envInfo.bgApp.lookupPath +
      '/lookup/item/find/list?classifyCode=PROJECT_LABEL',
    method: 'get',
  });
}

/**
 * 根据公司id查询部门
 * @param params
 */
/*export function selectEmpDept(companyId) {
    return request({
        url: envInfo.bgApp.organizationPath + `/tenant/dept/list?company=${companyId}`,
        method: 'get'
    })
}*/

/**
 * 根据部门id查询岗位
 * @param params
 */
/*export function selectEmpDuty(deptId) {
    return request({
        url: envInfo.bgApp.organizationPath + `/tenant/duty/list?deptId=${deptId}`,
        method: 'get'
    })
}*/
